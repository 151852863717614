import React, {useCallback, useEffect, useState} from "react";
import styles from "./Players.module.css";
import { PlayersItem } from "./PlayersItem";
import { useFormik } from "formik";
import {
  removePlayer,
  setPopupMessage
} from "../../../redux/feature/reducer";
import {
  createRoomSeatEmit,
  createMultiRoomSeat,
  sendAllInvitation,
  sendAllFeedbackEmail,
  exportSeatCSV
} from "../../../redux/feature/extraReducers";
import { Board } from "../../layout/Board";
import { useDispatch, useSelector } from "react-redux";
import { CreatePlayerSchema, isEmail } from "../../../services/validationService";
import { parseCSV } from "../../../services/commonService";
import { SUCCESS, ERROR, SIZE } from '../../../constants';
import { socket } from "../../../socket";
import { triggerShowStudentPrivacy } from '../../../redux/feature/reducer';
import XLSX from "xlsx"
import JSZip from "jszip";

export const Players = () => {
  var zip = new JSZip();
  const dispatch = useDispatch();
  const roomSelect = useSelector(({ roomSelect }) => roomSelect);
  
  console.log(roomSelect);
  const [seats, setSeats] = useState(roomSelect.seats);
  const [agreed, setAgreed] = useState(false);
  const [waitImportResponse, setWaitImportResponse] = useState();
  const addNewPlayer = (dataPlayer) => {
    dispatch(createRoomSeatEmit({ ...dataPlayer, roomID: roomSelect.roomID }));
  };

  const formData = useFormik({
    initialValues: {
      name: "",
      email: "",
      class: "",
    },
    onSubmit: (values) => {
      addNewPlayer(values);
      formData.resetForm();
    },
    validationSchema: CreatePlayerSchema
  });
  const idToStudentName = (id) =>{
    return `Student ${id}`;
  }
  const generateStudentName = (seatList)=>{
    console.log(roomSelect.seats);
    var id = 1;
    for(id = 1; id < 100000 ; id++){
      var repeatedName = false;
      for(var seatID in roomSelect.seats){
        var seat = roomSelect.seats[seatID];
        if(seat.ownerName == idToStudentName(id)) repeatedName = true;
      }
      for(var seatObj of seatList){
        if(seatObj.ownerName == idToStudentName(id)) repeatedName = true;
      }
      if(!repeatedName){
        return idToStudentName(id);
      }
    }
    return "noname";
  }
  const clickGenerateSeats = ()=>{
    var seatAmount = prompt("please enter the amount of new users", "5");
    if(seatAmount > 500){
      alert("amount cannot be larger than 500!");
    }
    var seatList = [];
    for(var i = 0 ; i < seatAmount ; i++){
      seatList.push({
        ownerEmail: "no@email.com", 
        ownerClass: "defaultClass", 
        ownerName: generateStudentName(seatList)});
    }
    console.log("sent creation of seatList: ", seatList);
    dispatch(createMultiRoomSeat({
      roomID: roomSelect.roomID,
      seatList: seatList
    }));
  }
  const onUploadFile = (e) => {
    if (!e.target.files || !e.target.files.length) {
      e.target.value = null;
      return false;
    }

    parseCSV(e.target.files[0], {
      complete: (result) => {
        if (result.errors && result.errors.length) {
          e.target.value = null;
          dispatch(setPopupMessage({ message: result.errors[0].message, type: ERROR }));
          return false;
        }

        let errorList = [];
        let seatList = result.data.map((data, index) => {
          //const ownerEmail = data[0];
          const ownerEmail = "no@email.com";//We don't use email in this version
          const ownerClass = data[0];
          const ownerName = data[1];
          if(data.length < 2 || ownerEmail == null 
            || ownerEmail == '') return null;
          if(index==0 &&( !isEmail(ownerEmail) || (!ownerName || !ownerName.trim()))){
            return null; //First row don't mind
          }
          let errors = [];
          if (!isEmail(ownerEmail)) errors.push('Invalid email.');////We don't use email in this version
          if (!ownerName || !ownerName.trim()) errors.push('Name is required.');
          if (errors.length) errorList.push({ line: index + 1, errors });
          return { ownerEmail, ownerClass, ownerName }
        })
        if (errorList.length) {
          let errorMessage = 'Errors:<br>';
          for(let i = 0; i < errorList.length; i++) {
            errorMessage += `Line ${errorList[i].line}: ${errorList[i].errors.join(' ')} <br>`;
            if (i >= 9) {
              errorMessage += '...';
              break;
            }
          }
          e.target.value = null;
          dispatch(setPopupMessage({ message: errorMessage, type: ERROR, size: SIZE.LG }));
          return false;
        }

        e.target.value = null;
        setWaitImportResponse(true);
        dispatch(setPopupMessage({ message: 'Importing...', keep_alive: true }));

        // Importing...
        dispatch(createMultiRoomSeat({
          roomID: roomSelect.roomID,
          seatList: seatList
        }));
      },
      error: (err, file, inputElem, reason) => {
        e.target.value = null;
        dispatch(setPopupMessage({ message: reason, type: ERROR }));
      }
    })
  }

  const onSuccess = useCallback((data) => {
    // Check imported
    if (waitImportResponse && data.roomID === roomSelect.roomID) {
      setWaitImportResponse(false);
      dispatch(setPopupMessage({ message: 'Successfully imported seats', type: SUCCESS }));
    }
  }, [dispatch, waitImportResponse, roomSelect]);

  const onError = useCallback((data) => {
    dispatch(setPopupMessage({ message: data.message, type: ERROR  }));
  }, [dispatch]);
  const user = useSelector(({ user }) => user);

  useEffect(() => {
    setSeats(roomSelect.seats);
    var invitationCodeList = [];
    Object.keys(roomSelect.seats).map((key) => {
      invitationCodeList.push(roomSelect.seats[key].invitationCode);
    });
    //setTimeout(()=>{console.log(invitationCodeList)}, 1000);
  }, [roomSelect.seats]);

  useEffect(() => {

    dispatch(triggerShowStudentPrivacy(false));
    socket.on("addRoomSeatList", onSuccess);
    socket.on("error", onError);

    return (() => {
      socket.off("addRoomSeatList", onSuccess);
      socket.off("error", onError);
    })
  }, [dispatch, onSuccess, onError]);

  return (
    
    <Board>
      <div className={styles.mainContainer}>
        <div className={styles.containerBtn}>
          {
            (user?.permissionMap?.hasOwnProperty("sendInvitationToAll"))
            ?
            <button className={styles.sendBtn} onClick={()=>{
              dispatch(sendAllFeedbackEmail({ roomID: roomSelect.roomID }));
            }}>
                Send feedback email to all
            </button>
            :
            <div></div>
          }
          {
            (user?.permissionMap?.hasOwnProperty("sendInvitationToAll"))
            ?
            <button className={styles.sendBtn} onClick={()=>{
              dispatch(sendAllInvitation({ roomID: roomSelect.roomID }));
            }}>
                Send invitation to all
            </button>
            :
            <div></div>
          }
          <button className={styles.importBtn} onClick={()=>{clickGenerateSeats()}}>
              Generate accounts
          </button>
          {
            (true)//(user?.permissionMap?.hasOwnProperty("superAdmin"))
            ?
            <button className={styles.sendBtn} onClick={()=>{
              var aoa = [
                ["class", "name", "invitationCode"]
              ]
              for(var seatUUID in roomSelect.seats){
                var seat = roomSelect.seats[seatUUID];
                var seatCode = seat.seatCode;
                var invitationCode = seat.invitationCode;
                var ownerClass = seat.ownerClass;
                var name = seat.ownerName;
                var email = seat.ownerEmail;
                aoa.push([ownerClass, name, invitationCode])
              }
              var ws = XLSX.utils.aoa_to_sheet(aoa);
              var wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "seats");
              XLSX.writeFile(wb, "seats.xls");
            }}>
                Export accounts
            </button>
            :
            <></>
          }
          <button className={styles.importBtn}>
            <label htmlFor="upload-player-input">
              <input type="file" id="upload-player-input" onChange={(e) => onUploadFile(e)}  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
              Import accounts by csv
            </label>
          </button>
          
        </div>
        <div className={styles.namesList}>
          {//<div className={styles.email}>email</div>
          }
          <div className={styles.name}>name</div>
          <div className={styles.status}>status</div>
          <div className={styles.invitationCode}>invite code</div>
          <div className={styles.remove}>Remove</div>
        </div>
        <div className={styles.containerScroll}>
          <ul className={styles.listPlayers}a>
            {seats && Object.keys(seats).length > 0 ? (
              <>
                {Object.keys(seats).map((key) => {
                  return (
                    <PlayersItem
                      key={key}
                      data={seats[key]}
                      removePlayer={removePlayer}
                    />
                  );
                })}
              </>
            ) : (
              <div className={styles.playersInf}>List is empty. Start by 
              adding new players to this room</div>
            )}
          </ul>
        </div>
        {/*
        <div className={styles.containerAddSeat}>
          <div className={styles.AddSeatText}>
            <p>Add seat</p>
          </div>
          <form onSubmit={formData.handleSubmit}>
            <div className={styles.containerForm}>
              <div className={styles.boxFormAddSeat}>
                <div className={styles.boxFormAddSeatInput}>
                  <span className={styles.inputClass}>
                    <label htmlFor="class">class</label>
                    <input
                      id="class"
                      name="class"
                      type="class"
                      onChange={formData.handleChange}
                      value={formData.values.class}
                    />
                  </span>
                  <span className={styles.inputAddContainer}>
                    <label htmlFor="email">name</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      onChange={formData.handleChange}
                      value={formData.values.name}
                    />
                  </span>
                </div>
                {formData.errors.name && formData.touched.name ? (
                  <div
                    className={styles.passwordError}
                  >{`Error: ${formData.errors.name}`}</div>
                ) : null}
              </div>
              <button type="submit" className={styles.BtnAdd}>
                Add
              </button>
            </div>
          </form>
        </div>
                */}
      </div>
    </Board>
  );
};
