import React, {useEffect, useState} from "react";

import { useDispatch } from "react-redux";
import { roomListSelect } from "../../../../redux/feature/reducer";
import ListIcon from "./ListIcon.svg";
import { Link } from "react-router-dom";
import removeIcon from './remove.svg';
import styles from "./RoomsItem.module.css";
import { RemoveRoomConfirm } from '../RemoveRoomConfirm';
import { formatDate }  from '../../../../services/commonService';
import { useSelector } from "react-redux";

export const RoomsItem = ({ data }) => {
  const dispatch = useDispatch();
  const [showRemoveRoomConfirm, setShowRemoveRoomConfirm] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState();
  
  const user = useSelector(({ user }) => user);
  const selectRoom = () => {
    dispatch(roomListSelect(data.roomID));
  };
  const removeRoom = (e, roomID) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedRoomId(roomID);
    setShowRemoveRoomConfirm((show) => !show);
  };

  return (
    <div>
      {showRemoveRoomConfirm ? <RemoveRoomConfirm setShowModal={setShowRemoveRoomConfirm} selectedRoomId={selectedRoomId} /> : null}
      <Link
        to={`/${data.roomID}/leader-board`}
        onClick={selectRoom}
        style={{ textDecoration: "none" }}
      >
        <li>
          <div className={styles.image}>
            <img src={ListIcon} alt="icon" />
          </div>
          <div>
            <div className={styles.roomName}>
              <p>{data.name}</p>
              <p>{data.competition}</p>
            </div>
          </div>
          <div className={styles.date}>
            <p>Date:</p>
            <p>{formatDate(data.setting.timeSetting.startTime) || '-'}</p>
          </div>
          <div className={styles.time}>
            <p>Time: </p>
            <p>{ formatDate(data.setting.timeSetting.startTime, 'LT') }
              -
              { formatDate(data.setting.timeSetting.endTime,'LT') }
            </p>
          </div>
          <div className={styles.seats}>
            <p>Seats: </p>
            <p>{ Object.keys(data.seats).length }
            </p>
          </div>
            {(user?.permissionMap?.hasOwnProperty("superAdmin"))
              ?
              <div className={styles.remove}>
                <img
                  onClick={(e) => removeRoom(e, data.roomID)}
                  src={removeIcon}
                  alt={"remove"}
                />
              </div>
              :
                <div></div>
            }
        </li>
      </Link>
    </div>
  );
};
