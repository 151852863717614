export const jobPositions = [
  { id: 1, value: "teacher", name: "Teacher" },
  { id: 2, value: "technician", name: "Technician" },
  { id: 3, value: "principle", name: "Principle" },
];

export const IMPORT_CSV_CHUCK = 50;

export const SUCCESS = 1;
export const ERROR = 0;
export const SIZE = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs'
}

export const REQUIRED_ROOM_ID_REGEX = 'dash-board|room-options|players|leader-board|leader-board-fullscreen';