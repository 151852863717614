import React, {useState, useEffect} from "react";
import { ListLeader } from "./listLeader/index";
import styles from "./leaderBoard.module.css";
import { useSelector } from "react-redux";
import background from './background.svg';
import exitFullscreenButton from "./exit-fullscreen-button.png";
import {Link} from "react-router-dom";

export const LeaderBoardFullScreen = () => {
  const leaderBoard = useSelector(({ leaderBoard }) => leaderBoard);
  const roomSelect = useSelector(({ roomSelect }) => roomSelect);
  const [leaderListRoom, setLeaderListRoom] = useState([]);

  useEffect(() => {
    const selectedLeaderBoard = leaderBoard[roomSelect.roomID] || [];
    console.log(selectedLeaderBoard);
    setLeaderListRoom([...selectedLeaderBoard]);
  }, [ leaderBoard, roomSelect.roomID ]);

  var median = (values)=>{
    if(values.length ===0) return 0
  
    values.sort(function(a,b){
      return a-b;
    });
  
    var half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;
  }
  var selectedLeaderBoard = leaderBoard[roomSelect.roomID];
  var listOfScores = [];
  if(selectedLeaderBoard!=null)
    for(var i = 0 ; i < selectedLeaderBoard.length ; i++){
      listOfScores.push(selectedLeaderBoard[i].score);
    }
  var m = median(listOfScores);
  return (
      <div className={styles.mainContainer} style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPositionY: "-250px",
        height: "100%"
      }}>
        <div className={styles.leaderBoardHeader}>
          {/*<div className={styles.median}>median: {m}</div>*/}
          <Link to={`/${roomSelect.roomID}/leader-board`} className={styles.leaderBoardLink}>
            <img src={exitFullscreenButton} alt="Exit fullscreen"/>
          </Link>
        </div>
        <div className={styles.title}>{roomSelect.name}</div>
        <ListLeader arrayList={leaderListRoom} />
      </div>
  );
};
