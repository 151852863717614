import { useState } from "react";
import copyIcon from "./copy.svg";
import removeIcon from "./remove.svg";
import { useDispatch } from "react-redux";
import { removeRoomSeatEmit } from "./../../../../redux/feature/extraReducers";

import styles from "./PlayersItem.module.css";
export const PlayersItem = ({ data }) => {
  const dispatch = useDispatch();
  const removePlayer = (roomID, seatCode) => {
    dispatch(removeRoomSeatEmit({ roomID, seatCode }));
  };
  const [MousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  });
  const [showHideUpdateRow, setShowHideUpdateRow] = useState(false);
  function handleMouseMove(ev) {
    setShowHideUpdateRow(true);
    setMousePosition({ left: ev.screenX, top: ev.screenY });
  }

  return (
    <li>
      <div
        style={{ display: "flex", alignItems: "center", height: "100%" }}
        onMouseMove={(ev) => handleMouseMove(ev)}
        onMouseOut={() => setShowHideUpdateRow(false)}
      >
        {//<div className={styles.email}>{data.ownerEmail}</div>
        }
        <div className={styles.name}>{data.ownerName}</div>
        <div
          className={`${styles.status} ${
            data.status === "online"
              ? styles.statusPlaying
              : styles.statusOffline
          }`}
        >
           {data.status}
        </div>
        <div className={styles.invitationCode}>{data.invitationCode}</div>
      </div>
      <div className={styles.remove}>
        <img
          onClick={() => removePlayer(data.roomID, data.seatCode)}
          src={removeIcon}
          alt={"remove"}
        />
      </div>

    </li>
  );
};
