import { LogoutConfirm } from "../queries/LogoutConfirm";
import { StudentPrivacyConfirm } from "../queries/StudentPrivacyConfirm";
import { useSelector } from "react-redux";
import React from "react";

export const GlobalComponent = () => {
  const isShowLogoutConfirm =  useSelector(({isShowLogoutConfirm}) => isShowLogoutConfirm);
  const isShowStudentPrivacyConfirm =  useSelector(({isShowStudentPrivacyConfirm}) => isShowStudentPrivacyConfirm);

  return (
    <div>
      { isShowLogoutConfirm ? <LogoutConfirm /> : null }
      { isShowStudentPrivacyConfirm ? <StudentPrivacyConfirm /> : null }
    </div>
  )
}