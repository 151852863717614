import React, { useState }  from "react";
import styles from "./layout.module.css";
import { TopBoard } from "./TopBoard";
import console from "./imgsMenu/console.svg";
import dashboard from "./imgsMenu/dashboard.svg";
import leaderBoard from "./imgsMenu/leaderboard.svg";
import options from "./imgsMenu/options.svg";
import permissions from "./imgsMenu/permissions.svg";
import players from "./imgsMenu/players.svg";
import back from "./imgsMenu/back.svg";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { triggerShowStudentPrivacy } from '../../redux/feature/reducer';

export const Board = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const roomID = useSelector(({roomSelect}) => roomSelect.roomID);
  const user = useSelector(({ user }) => user);

  return (
    <div className={styles.test}>
      <TopBoard />
      <div className={styles.leaderContainer}>
        <div className={styles.leftBar}>
          <ul>

            {(user?.permissionMap?.hasOwnProperty("superAdmin"))
              ?
              <div>
                <li>
                <NavLink
                to={ roomID ? `/${roomID}/room-options` : '/room-list' }
                  activeClassName={styles.selected}
                  className={styles.linkStyle}
                  style={{ textDecoration: "none" }}
                >
                  <img src={options} alt="icon" />
                  <p>Options</p>
                </NavLink>
              </li>
                
                <li>
                  <NavLink
                    to={`/permissions`}
                    activeClassName={styles.selected}
                    className={styles.linkStyle}
                    style={{ textDecoration: "none" }}
                  >
                    <img src={permissions} alt="icon" />
                    <p>Permissions</p>
                  </NavLink>
                </li>
                
                <li>
                  <NavLink
                    to={`/console`}
                    activeClassName={styles.selected}
                    className={styles.linkStyle}
                    style={{ textDecoration: "none" }}
                  >
                    <img src={console} alt="icon" />
                    <p>Console</p>
                  </NavLink>
                </li>
              </div>
              :
              <div></div>
            }
            <li>
              <NavLink
                to={ roomID ? `/${roomID}/dash-board` : '/room-list' }
                activeClassName={styles.selected}
                className={styles.linkStyle}
                style={{ textDecoration: "none" }}
              >
                <img src={dashboard} alt="icon" />
                <p>Dashboard</p>
              </NavLink>
            </li>
                <li onClick={()=>{
                  dispatch(triggerShowStudentPrivacy(true));
                  }}>
                  <NavLink
                    to={ roomID ? `/${roomID || ''}/players` : '/room-list' }
                    activeClassName={styles.selected}
                    className={styles.linkStyle}
                    style={{ textDecoration: "none" }}
                  >
                    <img src={players} alt="icon" />
                    <p>Players</p>
                  </NavLink>
                </li>
            <li>
              <NavLink
                to={roomID ? `/${roomID || ''}/leader-board` : '/room-list' }
                activeClassName={styles.selected}
                className={styles.linkStyle}
                style={{ textDecoration: "none" }}
              >
                <img src={leaderBoard} alt="icon" />
                <p>LeaderBoard</p>
              </NavLink>
            </li>
            <li className={styles.backButtonLi}>
              <NavLink
                to="/room-list"
                activeClassName={styles.selected}
                className={`${styles.linkStyle} ${styles.backButton}`}
                style={{ textDecoration: "none" }}
              >
                <img src={back} alt="icon" />
                <p>Back to room list</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
