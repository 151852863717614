import { LeaderBoard } from "./pages/LeaderBoard/index";
import { LeaderBoardFullScreen } from "./pages/LeaderBoardFullScreen/index";
import { Login } from "./pages/Login";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { Register } from "./pages/Register";
import { Players } from "./pages/Players";
import { RoomList } from "./pages/RoomList";
import { RoomOption } from "./pages/RoomOption";
import { User } from "./pages/User";
import { ResetPassword } from "./pages/ResetPassword";
import { EmailSend } from "./pages/ResetPassword/Email";
import { NewPassword } from "./pages/NewPassword";
import { PrivateRoute } from "./PrivateRouter";
import { DashBoard } from "./pages/DashBoard";
import { SocketHandler } from "./layout/socketHandler";
import React from "react";
import { PopupMessage } from './queries/PopupMessage';
import { GlobalComponent } from './layout/GlobalComponent';
import {useCallback, useEffect} from "react";
import './App.css'

function App() {
  useEffect(() => {
    const initialValue = document.body.style.zoom;

    // Change zoom level on mount
    document.body.style.zoom = "62.5%";
    document.body.style.backgroundSize = "100% 100%";
    document.body.style.height = "100%";

    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    }
  });
  return (
    <BrowserRouter>
      <PopupMessage />
      <GlobalComponent />
      <SocketHandler />
      <div>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route exact path="/reset/email" component={EmailSend} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/reset-password/:token" component={NewPassword} />
          <PrivateRoute path="/:roomID/leader-board">
            <LeaderBoard />
          </PrivateRoute>
          <PrivateRoute path="/:roomID/leader-board-fullscreen">
            <LeaderBoardFullScreen />
          </PrivateRoute>
          <PrivateRoute path="/user">
            <User />
          </PrivateRoute>
          <PrivateRoute path="/room-list">
            <RoomList />
          </PrivateRoute>
          <PrivateRoute path="/:roomID/dash-board">
            <DashBoard />
          </PrivateRoute>
          <PrivateRoute path="/:roomID/room-options">
            <RoomOption />
          </PrivateRoute>
          <PrivateRoute path="/:roomID/players">
            <Players />
          </PrivateRoute>
          <PrivateRoute path="/">
            <Redirect to="/login" />
          </PrivateRoute>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
