import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { socket } from "./../../socket/index";
import { Api } from "../../API/dotdotfire";

const userAPI = new Api();

const cookies = new Cookies();
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const loginUser = createAsyncThunk(
  "users/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      var newData = JSON.parse(JSON.stringify(data));
      if(!validateEmail(newData.email)){
        newData.email += "@dotdotfire.com"
      }
      console.log(newData);
      const response = await userAPI.LoginUser(newData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const registrationUser = createAsyncThunk(
  "users/Register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.register(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUser = createAsyncThunk(
  "users/getUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.getUser(cookies.get("userToken").token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const forgetPassword = createAsyncThunk(
  "users/forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.ForgetPassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userAPI.ResetPassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (data, { rejectWithValue }) => {
    data.accessToken = cookies.get("userToken").token;
    try {
      const response = await userAPI.updatePassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "users/logout",
  async (data, { rejectWithValue }) => {
    const token = cookies.get("userToken").token;
    try {
      const response = await userAPI.logout(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserDetail = createAsyncThunk("users/updateUserDetail", async (data) => {
  socket.emit("updateUserDetail", data);
});

//socket
export const createRoom = createAsyncThunk("users/createRoom", async (data) => {
  socket.emit("createRoom", data);
});

export const removeRoomEmit = createAsyncThunk("users/removeRoom", async (roomID) => {
  socket.emit("removeRoom", {
    roomID: roomID
  });
});

export const updateOptionEmit = createAsyncThunk(
  "users/updateOptionEmit",
  async (data) => {
    socket.emit("updateRoomSetting", {
      roomID: data.roomID,
      setting: data.setting,
    });
  }
);

export const changeNameEmit = createAsyncThunk(
  "users/changeName",
  async (data) => {
    socket.emit("updateRoomName", {
      roomID: data.roomID,
      name: data.name,
    });
  }
);
export const changeDescriptionEmit = createAsyncThunk(
  "users/changeDescription",
  async (data) => {
    socket.emit("updateRoomDescription", {
      roomID: data.roomID,
      description: data.description,
    });
  }
);
export const createRoomSeatEmit = createAsyncThunk(
  "users/createRoomSeatEmit",
  async (data) => {
    socket.emit("createRoomSeat", {
      roomID: data.roomID,
      ownerName: data.name,
      ownerEmail: data.email,
      ownerClass: data.class,
    });
  }
);
export const sendAllInvitation = createAsyncThunk(
  "users/sendAllInvitation",
  async (data) => {
    socket.emit("sendAllInvitation", {
      roomID: data.roomID,
    });
  }
);
export const sendAllFeedbackEmail = createAsyncThunk(
  "users/sendAllFeedbackEmail",
  async (data) => {
    socket.emit("sendAllFeedbackEmail", {
      roomID: data.roomID,
    });
  }
);

export const createMultiRoomSeat = createAsyncThunk(
  "users/createRoomSeatEmit",
  async (data) => {
    socket.emit("createRoomSeatList", data);
  }
);

export const removeRoomSeatEmit = createAsyncThunk(
  "users/removeRoomSeat",
  async (data) => {
    socket.emit("removeRoomSeat", {
      roomID: data.roomID,
      seatCode: data.seatCode,
    });
  }
);
