import React from "react";
import styles from "./leaderList.module.css";
import crownIcon from './Mascot_Crown.png';

export const ListItem = ({ name, color, borderColor, score, rank, status }) => {
  return (
    <div className={styles.listItem} style={{ background: color, borderColor: borderColor }}>
      <div className={`${styles.rank} ${styles.cellLeader} `} style={
        rank==1?
      {
        border: "1px solid red",
        backgroundColor: "red"
      }
      :rank==2?
      {
        border: "1px solid silver",
        backgroundColor: "silver"
      }
      :rank==3?
      {
        border: "1px solid #F1A975",
        backgroundColor: "#F1A975"
      }:{}
    }>
      {rank==1
      ?
      <>
        <img src={crownIcon} className={styles.crownIcon}/>
      </>
      :
      <p>{rank}</p>
      }
      </div>
      <div className={`${styles.name} ${styles.cellLeader} `}>
        <p>{name}</p>
      </div>
      <div className={`${styles.score} ${styles.cellLeader} `}>
        <p>{score}</p>
      </div>
      <div className={`${styles.status} ${styles.cellLeader}`}>
        <div className={`${styles.rankState} ${styles[status]} `}></div>
      </div>
    </div>
  );
};
