import { Route, useHistory } from "react-router-dom";
import {setRoomSelectID, setPopupMessage, setIsOnline, logout} from "../redux/feature/reducer";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {socket, disconnect} from "../socket";
import {isNetworkError, isUnauthorized} from "../services/authService";
import Cookies from "universal-cookie";

export const PrivateRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isPageLoaded = useSelector(({isPageLoaded}) => isPageLoaded);
  const cookies = new Cookies();

  const handleLogout = useCallback(() => {
    setTimeout(() => {
      dispatch(setPopupMessage(null));
      disconnect();
      dispatch(logout());
      if (history.location.pathname.indexOf('/login') === -1) {
        history.push(`/login?redirect=${encodeURIComponent(history.location.pathname)}`);
      }
    }, 2000);
  }, [dispatch, history]);

  const render = (props) => {
    if (!cookies.get('userToken')) {
      disconnect();
      if (history.location.pathname.indexOf('/login') === -1) {
        history.push(`/login?redirect=${encodeURIComponent(history.location.pathname)}`);
      }
    }

    let roomID = props.match.params ? props.match.params.roomID : null;
    if (roomID) {
      dispatch(setRoomSelectID(roomID));
    }
    return children;
  }

  useEffect(() => {
    if (cookies.get('userToken') && !isPageLoaded) {
      dispatch(setPopupMessage({message: "Loading...", keep_alive: true}));
    }
  }, [dispatch, isPageLoaded])

  useEffect(() => {
    // Error handler
    socket.on("connect_error", (err) => {
      if (isNetworkError(err.message)) {
        dispatch(setIsOnline(false))
      }

      if (isUnauthorized(err.message)) {
        handleLogout();
      }
    });

    socket.on("error", (err) => {
      if (isUnauthorized(err.message)) {
        handleLogout();
      }
    });
    return (() => {
      socket.off('connect_error');
      socket.off('error');
    })
  })

  return <Route {...rest} render={render} />;
};
