import React from "react";
import styles from "./leaderList.module.css";
import { ListItem } from "./listItem";

const colorPlace = {
  first: "#FFFFFF",
  second: "#FFFFFF",
  third: "#FFFFFF",
  other: "#F6F6F6",
};

export const ListLeader = ({ arrayList }) => {
  const listItems = (arrayList && arrayList.length > 0) ? arrayList
    .sort((a, b) => b.score - a.score)
    .map((data, index) => {
      const place = index + 1;
      return (
        <ListItem
          key={index}
          name={data.name}
          rank={place}
          color={
            place === 1
              ? colorPlace.first
              : place === 2
              ? colorPlace.second
              : place === 3
              ? colorPlace.third
              : colorPlace.other
          }
          borderColor={
            place === 1
              ? "rgba(0,255,255,255)"
              : place === 2
              ? colorPlace.second
              : place === 3
              ? colorPlace.third
              : colorPlace.other
          }
          score={data.score}
          status={data.status}
        />
      );
    }) : <div></div>;

  return (
      <div className={styles.containerScroll}>{
        listItems && listItems.length ? listItems : <div className={styles.noData}>List is empty. Start by 
        adding new room</div>
      }</div>
  );
};
