import React, {useState, useEffect, useCallback} from "react";
import { ListLeader } from "./listLeader/index";
import styles from "./leaderBoard.module.css";
import { SelectList } from "../../queries/SelectList";
import { Board } from "../../layout/Board";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import expandLogo from './expand-solid.png';
import exportLogo from './file-export-solid.png';
import historyLogo from './history-logo.png';
import XLSX from "xlsx"
import stats from "stats-lite";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
const parser = require('csv-parse/lib/sync') 
export const LeaderBoard = (callback, deps) => {
  const leaderBoard = useSelector(({ leaderBoard }) => leaderBoard);
  const roomSelect = useSelector(({ roomSelect }) => roomSelect);
  const listRooms = useSelector(({ listRooms }) => listRooms);
  const [roomSelection, setRoomSelection] = useState([]);
  const [leaderListRoom, setLeaderListRoom] = useState([]);

  const changeRoom = useCallback((roomID) => {
    const selectedLeaderBoard = leaderBoard[roomID] || [];
    setLeaderListRoom([...selectedLeaderBoard]);
  }, [leaderBoard]);

  var getLeaderboard = ()=>{
    /*
    [
        {//index: 0 which is No.1
            name: "Matthew Choi",
            class: "4B",
            score: 500
        },
        {//index: 1 which is No.2
            name: "Stephen Yip",
            class: "3B",
            score: 400
        },
    ]
    */
   var playerList = [];
    for(var seatCode in roomSelect.seats){
      var seat = roomSelect.seats[seatCode];
        playerList.push({
            name: seat.ownerName,
            class: seat.ownerClass,
            score: seat.playerData.score,
        })
    }
    var byScore = playerList.slice(0);
    var sortedLeaderboard = byScore.sort(function(a,b) {
        return a.score - b.score;
    });
    return sortedLeaderboard;
}
const exportLeaderboard = ()=>{
  console.log("clicked leaderboard export", roomSelect.seats);
  if(roomSelect == null) return;
  if(roomSelect.seats == null) return;
  //Loop all seats
  var leaderboard = getLeaderboard();
  var aoa = [
    ["Rank", "Class", "Name", "Score"]
  ];
  var mean = 0;
  var median = 0;
  var scoreArr = [];
  for(var i = leaderboard.length-1 ; i >= 0; i--){
    var player = leaderboard[i];
    var rank = leaderboard.length - i;
    var arr = [rank, player.class, player.name, player.score];
    scoreArr.push(player.score);
    aoa.push(arr);
  }
  if(scoreArr == null) return;
  if(scoreArr.length === 0) return;
  var mean = stats.mean(scoreArr);
  var median = stats.median(scoreArr);

  aoa.push([]);
  aoa.push(["Total number of participants:","", "", leaderboard.length]);//the "","" is for merging
  aoa.push(["mean: ","", "", mean]);//the "","" is for merging
  aoa.push(["median: ","", "", median]);//the "","" is for merging
  const ws = XLSX.utils.aoa_to_sheet(aoa);
  const merge = [
    { s: { r: leaderboard.length+2, c: 0 }, e: { r: leaderboard.length+2, c: 2 } },
    { s: { r: leaderboard.length+3, c: 0 }, e: { r: leaderboard.length+3, c: 2 } },
    { s: { r: leaderboard.length+4, c: 0 }, e: { r: leaderboard.length+4, c: 2 } },
  ];
  ws["!merges"] = merge;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, `${roomSelect.name} ${(new Date()).toLocaleString()} student performance results.xlsx`)
}
  
function loadQuests(csvString){
  try{
      var quests = {};
      var csv = parser.parse(csvString, {
          columns: true,
          skip_empty_lines: false
      });
      for(var row of csv){
          var questID = row["Quest ID"].toUpperCase();
          quests[questID] = {};
          for(var key of Object.keys(row)){
              quests[questID][key] = row[key];
          }
          //console.log("loaded quest: ", quests[questID])
      }
      //console.log("loaded quests: ", quests)
  }catch(e){
    console.log(e);
  }
  return quests;
}
function debug(o){
  console.log(o);
}
function getQuestKey(quests, questID, key){
    var quest = quests[questID.toUpperCase()];
    if(quest == null) return null;
    return quest[key];
}
function getAnswerScore(quests, questID, optionID){
    var optionResult = getQuestKey(quests, questID, `Option ${optionID} Tag Out`);
    
    debug("run answer result: ", "questID", questID, "optionID", optionID, "result", optionResult);
    if(optionResult == null){
        debug("optionResult is null")
        return 0;
    }
    optionResult = optionResult.toUpperCase();
    var cmdList = optionResult.split(";");

    for(var cmd of cmdList){
        debug("checking cmd: "+cmd);
        if(cmd.includes("ASCORE:")){
            var amountStr = cmd.replace("ASCORE:", "").trim();
            var amount = parseInt(amountStr);
            return amount;
        }else if(cmd.includes("ADDSCORE:")){
            var amountStr = cmd.replace("ADDSCORE:", "").trim();
            var amount = parseInt(amountStr);
            return amount;
        }
    }
    return 0;
}
function getCorrectOption(quests, questID){
  var highestScore = 0;
  var bestOption = -1;
  for(var i = 0 ; i < 6 ; i++){
    var addScore = getAnswerScore(quests, questID, i);
    console.log(`addScore of option ${i}: ${addScore}`);
    if(addScore > highestScore){
      bestOption = i;
      highestScore = addScore;
    }
  }
  return bestOption;
}
const exportAnswerHistory = ()=>{
  console.log("clicked answer history export", roomSelect.seats);
  if(roomSelect == null) return;
  if(roomSelect.seats == null) return;
  //Loop all seats
  var zip = new JSZip();
  console.log("room", roomSelect);
  var quests = loadQuests(roomSelect.setting.gameSetting.questCSV);
  console.log(quests);
  var questionsData = {};
  for(var seatCode in roomSelect.seats){
    var seat = roomSelect.seats[seatCode];
    var aoa = [
      ["QuestionID", "SelectedOption", "CorrectOption", "Correct?", "Score after answered"]
    ];
    //console.log(seat);
    var score = 0;
    for(var id in seat.playerData.answerHistory){
      var answerRequest = seat.playerData.answerHistory[id];
      var questID = answerRequest.questID;
      var optionID = answerRequest.optionID;
      var addedScore = getAnswerScore(quests, questID, optionID);
      var correctOptionID = getCorrectOption(quests, questID);
      if(addedScore == 0){
        continue;
      }
      if(questID in questionsData){
        var data = questionsData[questID];
        if(data==null){
          data = [0, 0];//[total answers, total correct count]
          questionsData[questID] = data;
        }
      }else{
        questionsData[questID] = [0, 0];
      }
      //Add total answer amount
      questionsData[questID][0] += 1;

      var correct = addedScore > 0;
      score += addedScore;

      //Add total correct count
      if(correct)
        questionsData[questID][1] += 1;

      aoa.push([questID, optionID, correctOptionID, correct, score])
      console.log([questID, optionID, correctOptionID, correct, score]);
      //console.log(answerRequest);
      //aoa.push(answerRequest);
    }
  
    var ws = XLSX.utils.aoa_to_sheet(aoa);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "history"/*seat.ownerName*/);
    //Zipping them
    var csv = XLSX.utils.sheet_to_csv(ws);
    //Add the folder
    var folder = zip.folder(seat.ownerClass);
    folder.file(`${seat.ownerName}.csv`, csv);


  }

  //Making the overall question analysis
  var questionsDataAOA = [["QuestionID", "content", "total answers", "correct count", "correct rate"]];
  for(var questID in questionsData){
    var questionData = questionsData[questID];
    var questionContents = quests[questID].Content;
    console.log("fuck", questionContents);
    var totalAnswerCount = questionData[0];
    var totalCorrectCount = questionData[1];
    var totalWrongCount = totalAnswerCount - totalCorrectCount;
    var totalCorrectRate = totalCorrectCount/totalAnswerCount;
    var totalWrongRate = totalWrongCount/totalAnswerCount;
    questionsDataAOA.push([questID, questionContents, totalAnswerCount, totalCorrectCount, 100*totalCorrectRate.toFixed(2)+"%"]);
  }
  var ws = XLSX.utils.aoa_to_sheet(questionsDataAOA);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "questions analysis");
  //Zipping them
  var csv = XLSX.utils.sheet_to_csv(ws);
  //Add the folder
  var folder = zip.folder("question analysis");
  folder.file(`${roomSelect.name} ${(new Date()).toLocaleString()} questionAnalysis.csv`, csv);


  zip.generateAsync({type:"blob"}).then(function(content) {
      // see FileSaver.js
      saveAs(content, `${roomSelect.name} ${(new Date()).toLocaleString()} history.zip`);
  });
  /* generate XLSX file and send to client */
  /*XLSX.writeFile(wb, `answer history of ${seat.ownerName}.zip`, {
    bookType:"xlsx",
  })*/
    
}

  useEffect(() => {
    const roomSelectionMap = listRooms ? listRooms.map((room) => {
      return {
        id: room.roomID,
        name: room.name,
        value: room.roomID,
      };
    }) : [];
    setRoomSelection(roomSelectionMap);
    changeRoom(roomSelect.roomID);
  }, [listRooms, changeRoom, roomSelect.roomID]);

  useEffect(() => {
    changeRoom(roomSelect.roomID);
  }, [leaderBoard, changeRoom, roomSelect, roomSelect.roomID]);

  const user = useSelector(({ user }) => user);
  return (
    <Board>
      <div className={styles.mainContainer}>
        <Link to={`/${roomSelect.roomID}/leader-board-fullscreen`} style={{ textDecoration: "none" }}>
          <img className={styles.expandLogo} src={expandLogo}/>
        </Link>
        <div onClick={exportLeaderboard}>
          <img className={styles.exportLogo} src={exportLogo}/>
        </div>
        {
          (user?.permissionMap?.hasOwnProperty("superAdmin"))?
          <div onClick={exportAnswerHistory}>
            <img className={styles.historyLogo} src={historyLogo}/>
          </div>
          :<div></div>
        }
        <ListLeader arrayList={leaderListRoom} />
      </div>
    </Board>
  );
};
