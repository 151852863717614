import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setIsOnline,
  setIsPageLoaded,
  updateUser,
  setRoomsList,
  addNewRoom,
  removeRoom,
  changeDescription,
  changeName,
  updateSetting,
  addSeat,
  addRoomSeatList,
  updateSeat,
  removePlayer,
  updateLeaderboard,
  setPopupMessage
} from "../../redux/feature/reducer";
import { socket } from "../../socket";
import { useHistory } from "react-router-dom";
import { saveLeaderBoardDemo } from "../../services/commonService"; /* REMOVE IT ON PRODUCTION */

export const SocketHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* REMOVE IT ON PRODUCTION */
  const roomSelect = useSelector(({ roomSelect }) => roomSelect);
  useEffect(() => {
    if (process.env.REACT_APP_USE_DEMO_LEADER_BOARD && roomSelect.roomID) {
        dispatch(updateLeaderboard(saveLeaderBoardDemo(roomSelect.roomID)));
    }
  }, [dispatch, roomSelect, roomSelect.roomID])
  /* REMOVE IT ON PRODUCTION */

  const handleLogout = useCallback(() => {
    dispatch(setPopupMessage(null));
    history.push(`/login?redirect=${encodeURIComponent(history.location.pathname)}`);
  }, [dispatch, history]);

  useEffect(() => {
    // User
    socket.on("loadUser", (data) => {
      dispatch(updateUser(data));
    });
    socket.on("updateUserDetail", (data) => {
      dispatch(updateUser(data));
    });

    // Room
    socket.on("loadAllRooms", (data) => {
      dispatch(setIsPageLoaded(true));
      dispatch(setRoomsList(data));
    });
    socket.on("addRoom", (data) => {
      dispatch(addNewRoom(data));
    });
    socket.on("removeRoom", (data) => {
      dispatch(removeRoom(data));
    });

    // Dashboard
    socket.on("updateRoomName", (data) => {
      dispatch(changeName(data));
    });
    socket.on("updateRoomDescription", (data) => {
      dispatch(changeDescription(data));
    });

    // Option
    socket.on("updateRoomSetting", (data) => {
      dispatch(updateSetting(data));
    });

    // Player
    socket.on("addRoomSeat", (data) => {
      dispatch(addSeat(data));
    });
    socket.on("addRoomSeatList", (data) => {
      dispatch(addRoomSeatList(data));
    });
    socket.on("updateRoomSeat", (data) => {
      dispatch(updateSeat(data));
    });
    socket.on("removeRoomSeat", (data) => {
      dispatch(removePlayer(data));
    });

    // Leader page
    socket.on("updateLeaderboard", (data) => {
      dispatch(updateLeaderboard(data));
    });

    socket.on('connect', () => {
      dispatch(setIsOnline(true));
    });

    // Component will unmount
    return () => {
      socket.off('loadUser');
      socket.off('updateUserDetail');
      socket.off('loadAllRooms');
      socket.off('addRoom');
      socket.off('removeRoom');
      socket.off('updateRoomName');
      socket.off('updateRoomDescription');
      socket.off('updateRoomSetting');
      socket.off('addRoomSeat');
      socket.off('updateRoomSeat');
      socket.off('removeRoomSeat');
      socket.off('updateLeaderboard');
      socket.off('connect');
      socket.off('connect_error');
      socket.off('error');
    };
  }, [dispatch, history, handleLogout]);

  return (
    <div></div>
  );
}
