import React from "react";

import {useDispatch, useSelector} from "react-redux";
import styles from "./StudentPrivacyConfirm.module.css";
import { agreeStudentPrivacy } from '../../../redux/feature/reducer';
import { disagreeStudentPrivacy } from '../../../redux/feature/reducer';
import { useHistory } from "react-router-dom";
//Checkbox
import checkedLogo from './check.png';
import Checkbox from "react-custom-checkbox";

export const StudentPrivacyConfirm = () => {
  const roomID = useSelector(({roomSelect}) => roomSelect.roomID);
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const acceptPrivacy = () => {
    dispatch(agreeStudentPrivacy(false));
    /*
    disconnect();
    dispatch(setIsShowStudentPrivacyConfirm(false));
    dispatch(logoutUser());
    dispatch(logout());
    history.push('/login');
    */
  }

  const backToDashboard = () =>{ 
    dispatch(disagreeStudentPrivacy(false));
    let path = `/${roomID}/dash-board`; 
    history.push(path);
  }
  const tickCheckbox = (e) => {
    var currentChecked = !checked;
    setChecked(currentChecked);
  }
  return (
    <div>
      <div className={styles.Modal}>
        <div className={styles.Modal_Body}>
          <p className={styles.title}>Student Privacy</p>
          <div className={styles.blockRemoveRoom}>
            <div className={styles.agreementRegion}>
              <Checkbox
                checked={checked}
                icon={<img src={checkedLogo} style={{ width: 24 }} alt="" />}
                borderColor="#000000"
                borderRadius={10}
                size={30}
                onChange={tickCheckbox}
              />
              <label className={styles.agreementText}>By ticking this box, you, on behalf of your institution/organisation, confirm that you have obtained all necessary consent and permission from (i) the students as participants of the Dot Dot Fire Competition Platform (the "Participants"); and/or (ii) the parents/guardians of the Participants, where the Participants is under the age of 18 to: (a) take part in the Dot Dot Fire Competition Platform (the “Championship”): and (b) transfer the personal data of the Participants (including the name, class name, photographs, and the school email address of the Participants) to Dot Dot Fire Limited and The Education University of Hong Kong as the organisers of the Championship for the following purposes:
- Organising and communicating with the Participants regarding the Championship;
- Communicating with the Championship winners and arranging delivery of prizes; and
- Publicising for the Championship on websites and social media platforms.</label>
            </div>
            <div className={styles.btnContainer}>
              <button
                className={styles.btn_cancel}
                onClick={backToDashboard}
              >
                Cancel
              </button>
              {
                checked
                ?
                <button
                className={styles.btn_accept}
                onClick={acceptPrivacy}>
                  Confirm
                </button>
                :
                <button
                className={styles.btn_accept_gray}>
                  Confirm
                </button>
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
